.Video-part-section {
  height: 300px;
  width: 500px;
}
@media only screen and (max-width: 768px) {
  .Video-part-section {
    height: 30vh;
    width: 100%;
  }
}

@media all {
  body,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  iframe,
  li,
  p,
  ul {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
  }
  body {
    margin: 0;
  }
  a {
    background-color: transparent;
  }
  a:active {
    outline: 0;
  }
  a,
  a:focus,
  a:hover,
  a:visited {
    text-decoration: none;
  }
  strong {
    font-weight: 700;
  }
  mark {
    background: #ff0;
    color: #000;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  figure {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
  }
  h1 {
    color: #808285;
    font-size: 2em;
    line-height: 1.2;
  }
  h2 {
    color: #808285;
    font-size: 1.7em;
    line-height: 1.3;
  }
  h3 {
    color: #808285;
    font-size: 1.5em;
    line-height: 1.4;
  }
  h4 {
    color: #808285;
    line-height: 1.5;
    font-size: 1.3em;
  }
  h5 {
    color: #808285;
    line-height: 1.6;
    font-size: 1.2em;
  }
  h6 {
    color: #808285;
    line-height: 1.7;
    font-size: 1.1em;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  body {
    color: #808285;
    background: #fff;
    font-style: normal;
  }
  ul {
    margin: 0 0 1.5em 3em;
  }
  ul {
    list-style: disc;
  }
  strong {
    font-weight: 700;
  }
  cite,
  em,
  i {
    font-style: italic;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  mark {
    background: 0 0;
    text-decoration: none;
  }
  a {
    color: #4169e1;
  }
  a:focus,
  a:hover {
    color: #191970;
  }
  a:focus {
    outline: thin dotted;
  }
  a:hover {
    outline: 0;
  }
  iframe {
    max-width: 100%;
  }
  ::selection {
    color: #fff;
    background: #0274be;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body:not(.logged-in) {
    position: relative;
  }
  a,
  a:focus {
    text-decoration: none;
  }
  a {
    transition: all 0.2s linear;
  }
  img {
    vertical-align: middle;
  }
  p {
    margin-bottom: 1.75em;
  }
  body {
    line-height: 1.85714285714286;
  }
  body {
    background-color: #fff;
  }
  body {
    overflow-x: hidden;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/astra/astra-theme-dynamic-css-post-12492.css?ver=1700719508 ; media=all */
@media all {
  a {
    color: var(--ast-global-color-0);
  }
  a:hover,
  a:focus {
    color: var(--ast-global-color-1);
  }
  body {
    /* font-family: "Poppins", sans-serif; */
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5em;
  }
  p {
    margin-bottom: 1em;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* font-family: "Poppins", sans-serif; */
    font-weight: 600;
  }
  h1 {
    font-size: 42px;
    font-size: 2.625rem;
    font-weight: 600;
    /* font-family: "Poppins", sans-serif; */
    line-height: 1.4em;
  }
  h2 {
    font-size: 36px;
    font-size: 2.25rem;
    font-weight: 600;
    /* font-family: "Poppins", sans-serif; */
    line-height: 1.25em;
  }
  h3 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 500;
    /* font-family: "Poppins", sans-serif; */
    line-height: 1.15em;
  }
  h4 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: 600;
    /* font-family: "Poppins", sans-serif; */
  }
  h5 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 600;
    /* font-family: "Poppins", sans-serif; */
  }
  h6 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 600;
    /* font-family: "Poppins", sans-serif; */
  }
  ::selection {
    background-color: var(--ast-global-color-0);
    color: #000000;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--ast-global-color-3);
  }
  .ast-single-post .elementor-button-wrapper .elementor-button {
    text-decoration: none;
  }
  .elementor-button-wrapper .elementor-button {
    border-style: solid;
    text-decoration: none;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
  }
  body .elementor-button.elementor-size-lg,
  body .elementor-button {
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 30px;
  }
  @media (max-width: 921px) {
    .elementor-button-wrapper .elementor-button.elementor-size-lg,
    .elementor-button-wrapper .elementor-button {
      padding-top: 14px;
      padding-right: 28px;
      padding-bottom: 14px;
      padding-left: 28px;
    }
  }
  @media (max-width: 544px) {
    .elementor-button-wrapper .elementor-button.elementor-size-lg,
    .elementor-button-wrapper .elementor-button {
      padding-top: 12px;
      padding-right: 24px;
      padding-bottom: 12px;
      padding-left: 24px;
    }
  }
  .elementor-button-wrapper .elementor-button {
    border-color: var(--ast-global-color-0);
    background-color: var(--ast-global-color-0);
  }
  .elementor-button-wrapper .elementor-button:hover,
  .elementor-button-wrapper .elementor-button:focus {
    color: #ffffff;
    background-color: var(--ast-global-color-1);
    border-color: var(--ast-global-color-1);
  }
  .elementor-button-wrapper .elementor-button,
  .elementor-button-wrapper .elementor-button:visited {
    color: #000000;
  }
  .elementor-button-wrapper .elementor-button {
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1em;
  }
  body .elementor-button.elementor-size-lg,
  body .elementor-button {
    font-size: 16px;
    font-size: 1rem;
  }
  .elementor-widget-heading h1.elementor-heading-title {
    line-height: 1.4em;
  }
  .elementor-widget-heading h2.elementor-heading-title {
    line-height: 1.25em;
  }
  .elementor-widget-heading h3.elementor-heading-title {
    line-height: 1.15em;
  }
  .elementor-widget-heading h4.elementor-heading-title {
    line-height: 1.2em;
  }
  .elementor-widget-heading h5.elementor-heading-title {
    line-height: 1.2em;
  }
  .elementor-widget-heading h6.elementor-heading-title {
    line-height: 1.25em;
  }
  @media (max-width: 921px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 20px;
    }
  }
  @media (max-width: 544px) {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 21px;
    }
    h3 {
      font-size: 21px;
    }
    h4 {
      font-size: 16px;
      font-size: 1rem;
    }
  }
  cite {
    font-style: initial;
  }
  html body {
    --wp--custom--ast-default-block-top-padding: 3em;
    --wp--custom--ast-default-block-right-padding: 3em;
    --wp--custom--ast-default-block-bottom-padding: 3em;
    --wp--custom--ast-default-block-left-padding: 3em;
    --wp--custom--ast-container-width: 1200px;
    --wp--custom--ast-content-width-size: 1200px;
    --wp--custom--ast-wide-width-size: calc(
      1200px + var(--wp--custom--ast-default-block-left-padding) +
        var(--wp--custom--ast-default-block-right-padding)
    );
  }
  @media (max-width: 921px) {
    html body {
      --wp--custom--ast-default-block-top-padding: 3em;
      --wp--custom--ast-default-block-right-padding: 2em;
      --wp--custom--ast-default-block-bottom-padding: 3em;
      --wp--custom--ast-default-block-left-padding: 2em;
    }
  }
  @media (max-width: 544px) {
    html body {
      --wp--custom--ast-default-block-top-padding: 3em;
      --wp--custom--ast-default-block-right-padding: 1.5em;
      --wp--custom--ast-default-block-bottom-padding: 3em;
      --wp--custom--ast-default-block-left-padding: 1.5em;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--ast-global-color-2);
  }
  .elementor-widget-heading .elementor-heading-title {
    margin: 0;
  }
  .elementor-screen-only {
    top: 0 !important;
  }
  .ast-page-builder-template {
    background-color: var(--ast-global-color-5);
    background-image: none;
  }
  @media (max-width: 921px) {
    .ast-page-builder-template {
      background-color: var(--ast-global-color-5);
      background-image: none;
    }
  }
  @media (max-width: 544px) {
    .ast-page-builder-template {
      background-color: var(--ast-global-color-5);
      background-image: none;
    }
  }
}
/*! CSS Used from: Embedded */
body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--ast-global-color-0: var(--ast-global-color-0);
  --wp--preset--color--ast-global-color-1: var(--ast-global-color-1);
  --wp--preset--color--ast-global-color-2: var(--ast-global-color-2);
  --wp--preset--color--ast-global-color-3: var(--ast-global-color-3);
  --wp--preset--color--ast-global-color-4: var(--ast-global-color-4);
  --wp--preset--color--ast-global-color-5: var(--ast-global-color-5);
  --wp--preset--color--ast-global-color-6: var(--ast-global-color-6);
  --wp--preset--color--ast-global-color-7: var(--ast-global-color-7);
  --wp--preset--color--ast-global-color-8: var(--ast-global-color-8);
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}
body {
  margin: 0;
  --wp--style--global--content-size: var(--wp--custom--ast-content-width-size);
  --wp--style--global--wide-size: var(--wp--custom--ast-wide-width-size);
}
body {
  --wp--style--block-gap: 24px;
}
body {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
a:where(:not(.wp-element-button)) {
  text-decoration: none;
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/astra-addon/astra-addon-dynamic-css-post-12492.css?ver=1700719508 ; media=all */
@media all {
  .elementor-widget-heading h4.elementor-heading-title {
    line-height: 1.2em;
  }
  .elementor-widget-heading h5.elementor-heading-title {
    line-height: 1.2em;
  }
  .elementor-widget-heading h6.elementor-heading-title {
    line-height: 1.25em;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.17.2 ; media=all */
@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
  }
  .elementor *,
  .elementor :after,
  .elementor :before {
    box-sizing: border-box;
  }
  .elementor a {
    box-shadow: none;
    text-decoration: none;
  }
  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor):not(
      .elementor-widget-theme-post-content
    )
    figure {
    margin: 0;
  }
  .elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
  }
  .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
  }
  .elementor-invisible {
    visibility: hidden;
  }
  .elementor-align-center {
    text-align: center;
  }
  .elementor-align-center .elementor-button {
    width: auto;
  }
  .elementor-align-left {
    text-align: left;
  }
  .elementor-align-justify .elementor-button {
    width: 100%;
  }
  @media (max-width: 767px) {
    .elementor-mobile-align-center {
      text-align: center;
    }
    .elementor-mobile-align-justify .elementor-button {
      width: 100%;
    }
  }
  .elementor-widget {
    position: relative;
  }
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
  .elementor-widget:not(:last-child).elementor-widget__width-initial {
    margin-bottom: 0;
  }
  ul.elementor-icon-list-items.elementor-inline-items {
    display: flex;
    flex-wrap: wrap;
  }
  ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
    word-break: break-word;
  }
  @media (min-width: 1025px) {
    #elementor-device-mode:after {
      content: "desktop";
    }
  }
  @media (min-width: -1) {
    #elementor-device-mode:after {
      content: "widescreen";
    }
  }
  @media (max-width: -1) {
    #elementor-device-mode:after {
      content: "laptop";
      content: "tablet_extra";
    }
  }
  @media (max-width: 1024px) {
    #elementor-device-mode:after {
      content: "tablet";
    }
  }
  @media (max-width: -1) {
    #elementor-device-mode:after {
      content: "mobile_extra";
    }
  }
  @media (max-width: 767px) {
    #elementor-device-mode:after {
      content: "mobile";
    }
  }
  .e-con {
    --border-radius: 0;
    --border-block-start-width: 0px;
    --border-inline-end-width: 0px;
    --border-block-end-width: 0px;
    --border-inline-start-width: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: min(100%, var(--container-max-width, 1140px));
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-block-start: 0px;
    --margin-inline-end: 0px;
    --margin-block-end: 0px;
    --margin-inline-start: 0px;
    --padding-block-start: var(--container-default-padding-block-start, 10px);
    --padding-inline-end: var(--container-default-padding-inline-end, 10px);
    --padding-block-end: var(--container-default-padding-block-end, 10px);
    --padding-inline-start: var(--container-default-padding-inline-start, 10px);
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    margin-block-start: var(--bc-margin-block-start, var(--margin-block-start));
    margin-inline-end: var(--bc-margin-inline-end, var(--margin-inline-end));
    margin-block-end: var(--bc-margin-block-end, var(--margin-block-end));
    margin-inline-start: var(
      --bc-margin-inline-start,
      var(--margin-inline-start)
    );
    padding-inline-start: var(
      --bc-padding-inline-start,
      var(--padding-inline-start)
    );
    padding-inline-end: var(--bc-padding-inline-end, var(--padding-inline-end));
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, 0.3s),
      border var(--border-transition, 0.3s),
      box-shadow var(--border-transition, 0.3s),
      transform var(--e-con-transform-transition-duration, 0.4s);
  }
  .e-con.e-flex {
    --flex-direction: column;
    --flex-basis: auto;
    --flex-grow: 0;
    --flex-shrink: 1;
    flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
  }
  .e-con-full,
  .e-con > .e-con-inner {
    text-align: var(--text-align);
    padding-block-start: var(
      --bc-padding-block-start,
      var(--padding-block-start)
    );
    padding-block-end: var(--bc-padding-block-end, var(--padding-block-end));
  }
  .e-con-full.e-flex,
  .e-con.e-flex > .e-con-inner {
    flex-direction: var(--flex-direction);
  }
  .e-con,
  .e-con > .e-con-inner {
    display: var(--display);
  }
  .e-con.e-grid {
    --grid-justify-content: start;
    --grid-align-content: start;
    --grid-auto-flow: row;
  }
  .e-con.e-grid,
  .e-con.e-grid > .e-con-inner {
    grid-template-columns: var(--e-con-grid-template-columns);
    grid-template-rows: var(--e-con-grid-template-rows);
    justify-content: var(--grid-justify-content);
    align-content: var(--grid-align-content);
    grid-auto-flow: var(--grid-auto-flow);
  }
  .e-con-boxed.e-flex {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
  }
  .e-con-boxed.e-grid {
    justify-items: legacy;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .e-con-boxed {
    text-align: initial;
    gap: initial;
  }
  .e-con.e-flex > .e-con-inner {
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
  .e-con.e-grid > .e-con-inner {
    justify-items: var(--justify-items);
    align-items: var(--align-items);
  }
  .e-con > .e-con-inner {
    gap: var(--gap);
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 100%;
  }
  :is(.elementor-section-wrap, [data-elementor-id]) > .e-con {
    --margin-inline-end: auto;
    --margin-inline-start: auto;
    max-width: min(100%, var(--width));
  }
  .e-con .elementor-widget.elementor-widget {
    margin-block-end: 0;
  }
  .e-con:before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, 0.3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-block-start-width));
    left: calc(0px - var(--border-inline-start-width));
    width: max(
      100% + var(--border-inline-end-width) + var(--border-inline-start-width),
      100%
    );
    height: max(
      100% + var(--border-block-start-width) + var(--border-block-end-width),
      100%
    );
  }
  .e-con:before {
    transition: background var(--overlay-transition, 0.3s),
      border-radius var(--border-transition, 0.3s),
      opacity var(--overlay-transition, 0.3s);
  }
  .e-con .elementor-widget {
    min-width: 0;
  }
  .e-con .elementor-widget.e-widget-swiper {
    width: 100%;
  }
  .e-con > .e-con-inner > .elementor-widget > .elementor-widget-container,
  .e-con > .elementor-widget > .elementor-widget-container {
    height: 100%;
  }
  .e-con.e-con > .e-con-inner > .elementor-widget,
  .elementor.elementor .e-con > .elementor-widget {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    .e-con.e-flex {
      --width: 100%;
      --flex-wrap: wrap;
    }
  }
  .e-con {
    --bc-margin-block-start: var(--margin-top);
    --bc-margin-block-end: var(--margin-bottom);
    --bc-margin-inline-start: var(--margin-left);
    --bc-margin-inline-end: var(--margin-right);
    --bc-padding-block-start: var(--padding-top);
    --bc-padding-block-end: var(--padding-bottom);
    --bc-padding-inline-start: var(--padding-left);
    --bc-padding-inline-end: var(--padding-right);
  }
  .elementor-element .elementor-widget-container {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
  }
  .elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #69727d;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all 0.3s;
  }
  .elementor-button:focus,
  .elementor-button:hover,
  .elementor-button:visited {
    color: #fff;
  }
  .elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
  }
  .elementor-button-icon {
    flex-grow: 0;
    order: 5;
  }
  .elementor-button-text {
    flex-grow: 1;
    order: 10;
    display: inline-block;
  }
  .elementor-button.elementor-size-lg {
    font-size: 18px;
    padding: 20px 40px;
    border-radius: 5px;
  }
  .elementor-button .elementor-align-icon-left {
    margin-right: 5px;
    order: 5;
  }
  .elementor-button span {
    text-decoration: inherit;
  }
  .elementor-tab-title a {
    color: inherit;
  }
  .elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all 0.3s;
    color: #69727d;
    font-size: 50px;
    text-align: center;
  }
  .elementor-icon:hover {
    color: #69727d;
  }
  .elementor-icon i,
  .elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
  }
  .elementor-icon i:before,
  .elementor-icon svg:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }
  .elementor-element .swiper .swiper-slide figure {
    line-height: 0;
  }
  .elementor-element .elementor-swiper {
    position: relative;
  }
  .elementor-element .elementor-main-swiper {
    position: static;
  }
  .animated {
    animation-duration: 1.25s;
  }
  .animated.animated-slow {
    animation-duration: 2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .animated {
      animation: none;
    }
  }
  .elementor .elementor-element ul.elementor-icon-list-items {
    padding: 0;
  }
  @media (max-width: 767px) {
    .elementor .elementor-hidden-mobile {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .elementor .elementor-hidden-tablet {
      display: none;
    }
  }
  @media (min-width: 1025px) and (max-width: 99999px) {
    .elementor .elementor-hidden-desktop {
      display: none;
    }
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css?ver=8.4.5 ; media=all */
@media all {
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-pointer-events {
    touch-action: pan-y;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/elementor/css/post-14.css?ver=1699337810 ; media=all */
@media all {
  .elementor-kit-14 {
    --e-global-color-primary: #6ec1e4;
    --e-global-color-secondary: #54595f;
    --e-global-color-text: #7a7a7a;
    --e-global-color-accent: #61ce70;
    --e-global-color-d384b9c: #ff6d07;
    --e-global-color-24bc8aa: #ff700d;
    --e-global-color-e154cc6: #01ff85;
    --e-global-color-99a96a2: #283dd5;
    --e-global-color-f229bad: #010711;
    --e-global-color-c6c8c6c: #92fde7;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-c63b31f-font-family: "Montserrat";
    --e-global-typography-c63b31f-font-weight: normal;
  }
  body {
    overflow-x: inherit !important;
  }
  .elementor a,
  .elementor a:link,
  .elementor a:focus,
  .elementor a:active,
  .elementor a:hover {
    text-decoration: inherit !important;
  }
  .elementor a:link {
    transition: inherit;
  }
  .e-con {
    --container-max-width: 1140px;
  }
  .elementor-widget:not(:last-child) {
    margin-block-end: 20px;
  }
  .elementor-element {
    --widgets-spacing: 20px 20px;
  }
  @media (max-width: 1024px) {
    .e-con {
      --container-max-width: 1024px;
    }
  }
  @media (max-width: 767px) {
    .e-con {
      --container-max-width: 767px;
    }
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css?ver=3.17.1 ; media=all */
@media all {
  .elementor-animated-content {
    --translate: 0, 0;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/elementor/css/post-12492.css?ver=1699337820 ; media=all */
@media all {
  body {
    overflow-x: auto;
  }
  body.elementor-page-12492 u {
    color: #ff6d07;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/elementor/css/post-34120.css?ver=1699337817 ; media=all */
@media all {
  .elementor-34120 .elementor-element.elementor-element-1d04c83b {
    --display: flex;
    --gap: 10px 10px;
    --background-transition: 0.3s;
    --padding-block-start: 3rem;
    --padding-block-end: 3rem;
    --padding-inline-start: 0rem;
    --padding-inline-end: 0rem;
  }
  .elementor-34120
    .elementor-element.elementor-element-1d04c83b:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor1);
  }
  .elementor-34120 .elementor-element.elementor-element-1d04c83b,
  .elementor-34120 .elementor-element.elementor-element-1d04c83b::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-73748537 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: center;
    --align-items: center;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-def4128
    .elementor-heading-title {
    color: #ff6d07;
    font-size: 51px;
  }
  .elementor-34120
    .elementor-element.elementor-element-def4128
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-6a465618 {
    text-align: left;
  }
  .elementor-34120 .elementor-element.elementor-element-6a465618 img {
    width: 48%;
  }
  .elementor-34120 .elementor-element.elementor-element-28e0a9b {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-28e0a9b
    .elementor-heading-title {
    color: black;
    line-height: 1.4em;
    font-size: 30px;
  }
  .elementor-34120
    .elementor-element.elementor-element-28e0a9b
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-d384b9c);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5b83e9d8 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    .elementor-icon-list-text {
    color: var(--e-global-color-astglobalcolor5);
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b83e9d8
    > .elementor-widget-container {
    margin: 20px 0px 0px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-efb5660 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: space-evenly;
    --gap: 10px 10px;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-784e9534 {
    --display: flex;
    --background-transition: 0.3s;
    --padding-block-start: 0px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-784e9534.e-con {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-1414598f {
    --display: flex;
    --min-height: 0px;
    --flex-direction: row;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: center;
    --align-items: flex-end;
    --gap: 10px 10px;
    --flex-wrap: wrap;
    --align-content: flex-start;
    --background-transition: 0.3s;
    --border-radius: 8px 8px 8px 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1414598f:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #2d2d2d;
  }
  .elementor-34120 .elementor-element.elementor-element-1414598f,
  .elementor-34120 .elementor-element.elementor-element-1414598f::before {
    --border-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5da74196
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-d384b9c);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5da74196 {
    --e-icon-list-icon-size: 25px;
    --icon-vertical-offset: 0px;
    width: var(--container-widget-width, 48%);
    max-width: 48%;
    --container-widget-width: 48%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-5da74196
    .elementor-icon-list-text {
    color: white;
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5da74196
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #000000;
    border-radius: 8px 8px 8px 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5da74196.elementor-element {
    --align-self: flex-start;
  }
  .elementor-34120
    .elementor-element.elementor-element-7f107895
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-d384b9c);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-7f107895 {
    --e-icon-list-icon-size: 25px;
    --icon-vertical-offset: 0px;
    width: var(--container-widget-width, 48%);
    max-width: 48%;
    --container-widget-width: 48%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-7f107895
    .elementor-icon-list-text {
    color: white;
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-7f107895
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #000000;
    border-radius: 8px 8px 8px 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7f107895.elementor-element {
    --align-self: flex-start;
  }
  .elementor-34120
    .elementor-element.elementor-element-2bae90d4
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-d384b9c);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-2bae90d4 {
    --e-icon-list-icon-size: 25px;
    --icon-vertical-offset: 0px;
    width: var(--container-widget-width, 48%);
    max-width: 48%;
    --container-widget-width: 48%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-2bae90d4
    .elementor-icon-list-text {
    color: white;
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-2bae90d4
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #000000;
    border-radius: 8px 8px 8px 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-2bae90d4.elementor-element {
    --align-self: flex-start;
  }
  .elementor-34120
    .elementor-element.elementor-element-385bc16
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-d384b9c);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-385bc16 {
    --e-icon-list-icon-size: 25px;
    --icon-vertical-offset: 0px;
    width: var(--container-widget-width, 48%);
    max-width: 48%;
    --container-widget-width: 48%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-385bc16
    .elementor-icon-list-text {
    color: white;
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-385bc16
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #000000;
    border-radius: 8px 8px 8px 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-385bc16.elementor-element {
    --align-self: flex-start;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(8px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(8px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-item:hover
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-astglobalcolor1);
  }
  .elementor-34120 .elementor-element.elementor-element-4e9bd7a9 {
    --e-icon-list-icon-size: 15px;
    --icon-vertical-offset: 0px;
    width: var(--container-widget-width, 98%);
    max-width: 98%;
    --container-widget-width: 98%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-icon {
    padding-right: 10px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-item {
    align-items: start;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-item
    .elementor-icon-list-icon {
    margin-top: 8px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 18px;
    font-weight: 400;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .elementor-icon-list-text {
    color: var(--e-global-color-astglobalcolor5);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-d01881d {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5a9332bf img {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-34120 .elementor-element.elementor-element-5a9332bf {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-6341348
    .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor2);
    color: var(--e-global-color-astglobalcolor2);
    background-color: #fff015;
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6341348
    > .elementor-widget-container {
    margin: 20px 0px 20px 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-271fdad8
    .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor2);
    color: var(--e-global-color-astglobalcolor2);
    background-color: #fff015;
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-271fdad8
    > .elementor-widget-container {
    margin: 20px 0px 20px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-23e779ed {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-23e779ed
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor4);
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-23e779ed
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-04f7fb3 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-04f7fb3
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor4);
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-04f7fb3
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-d19a963 {
    --e-image-carousel-slides-to-show: 8;
  }
  .elementor-34120
    .elementor-element.elementor-element-d19a963
    .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-d19a963
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #c3c3c3;
    border-radius: 12px 12px 12px 12px;
  }
  .elementor-34120 .elementor-element.elementor-element-15ed511e {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --gap: 10px 10px;
    --background-transition: 0.3s;
    --margin-block-start: 0px;
    --margin-block-end: 0px;
    --margin-inline-start: 0px;
    --margin-inline-end: 0px;
    --padding-block-start: 20px;
    --padding-block-end: 20px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-15ed511e:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #1d1d1d;
  }
  .elementor-34120 .elementor-element.elementor-element-15ed511e,
  .elementor-34120 .elementor-element.elementor-element-15ed511e::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-125d7932 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-125d7932
    .elementor-heading-title {
    color: #fff015;
  }
  .elementor-34120
    .elementor-element.elementor-element-125d7932
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-12cfefcb {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --padding-block-start: 40px;
    --padding-block-end: 40px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-12cfefcb:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor1);
  }
  .elementor-34120 .elementor-element.elementor-element-12cfefcb,
  .elementor-34120 .elementor-element.elementor-element-12cfefcb::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-73be4078 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: center;
    --align-items: center;
    --background-transition: 0.3s;
    --border-radius: 15px 15px 15px 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-73be4078:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-34120 .elementor-element.elementor-element-73be4078,
  .elementor-34120 .elementor-element.elementor-element-73be4078::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-73be4078.e-con {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-457cd71 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --align-items: center;
    --background-transition: 0.3s;
    --padding-block-start: 5px;
    --padding-block-end: 5px;
    --padding-inline-start: 5px;
    --padding-inline-end: 5px;
  }
  .elementor-34120 .elementor-element.elementor-element-767156a {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --justify-content: center;
    --align-items: center;
    --gap: 10px 10px;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-767156a:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-34120 .elementor-element.elementor-element-767156a,
  .elementor-34120 .elementor-element.elementor-element-767156a::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-767156a.e-con {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-545ca707 img {
    width: 150px;
  }
  .elementor-34120 .elementor-element.elementor-element-92fb5e5 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-92fb5e5
    .elementor-heading-title {
    color: #000000;
    font-size: 17px;
  }
  .elementor-34120
    .elementor-element.elementor-element-92fb5e5
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-582c6669 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-582c6669
    .elementor-star-rating {
    font-size: 36px;
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-element-582c6669
    .elementor-star-rating
    i:not(:last-of-type) {
    margin-right: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-582c6669
    .elementor-star-rating
    i:before {
    color: #ffffff;
  }
  .elementor-34120
    .elementor-element.elementor-element-582c6669
    .elementor-star-rating
    i {
    color: #c1c1c1;
  }
  .elementor-34120
    .elementor-element.elementor-element-582c6669
    > .elementor-widget-container {
    padding: 4px 4px 4px 4px;
    background-color: #00b67a;
  }
  .elementor-34120
    .elementor-element.elementor-element-582c6669.elementor-element {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-7d87b016 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-7d87b016
    .elementor-heading-title {
    color: #000000;
    font-size: 17px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7d87b016
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-4791031d {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --gap: 10px 10px;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-4791031d:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-34120 .elementor-element.elementor-element-4791031d,
  .elementor-34120 .elementor-element.elementor-element-4791031d::before {
    --border-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d767c52
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d767c52
    .elementor-icon {
    font-size: 112px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d767c52
    .elementor-icon
    svg {
    height: 112px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d767c52
    > .elementor-widget-container {
    margin: -30px 0px -38px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-3d767c52 {
    width: var(--container-widget-width, 100%);
    max-width: 100%;
    --container-widget-width: 100%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-7352f3 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-7352f3
    .elementor-star-rating__title {
    color: #6a6a6a;
    font-size: 23px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7352f3
    .elementor-star-rating {
    font-size: 35px;
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-element-7352f3
    .elementor-star-rating
    i:not(:last-of-type) {
    margin-right: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7352f3.elementor-element {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-3d5de092 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d5de092
    .elementor-heading-title {
    color: #000000;
    font-size: 17px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3d5de092
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-1ece0f54 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-1ece0f54
    .elementor-heading-title {
    color: #000000;
    font-size: 17px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1ece0f54
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-618e1f8e {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-618e1f8e:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-34120 .elementor-element.elementor-element-618e1f8e,
  .elementor-34120 .elementor-element.elementor-element-618e1f8e::before {
    --border-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49
    .elementor-main-swiper {
    width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49.elementor-testimonial--layout-image_above
    .elementor-testimonial__footer {
    margin-bottom: 25px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49
    .elementor-testimonial__text {
    color: #000000;
    font-size: 16px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49
    .elementor-testimonial__content {
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49.elementor-testimonial--layout-image_above
    .elementor-testimonial__footer {
    padding: 0 20px 0 20px;
  }
  .elementor-34120 .elementor-element.elementor-element-5843406 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --gap: 20px 20px;
    --background-transition: 0.3s;
    --margin-block-start: 0px;
    --margin-block-end: 0px;
    --margin-inline-start: 0px;
    --margin-inline-end: 0px;
    --padding-block-start: 20px;
    --padding-block-end: 20px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5843406:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #1d1d1d;
  }
  .elementor-34120 .elementor-element.elementor-element-5843406,
  .elementor-34120 .elementor-element.elementor-element-5843406::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-f65e5f0 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-f65e5f0
    .elementor-heading-title {
    color:white;
  }
  .elementor-34120
    .elementor-element.elementor-element-f65e5f0
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-65c84b0 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-65c84b0
    .elementor-heading-title {
    color: #ffffff;
    font-size: 34px;
  }
  .elementor-34120
    .elementor-element.elementor-element-65c84b0
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-c4b531c {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --padding-block-start: 20px;
    --padding-block-end: 20px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-ff1aef8 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-ff1aef8
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-ab11b67 {
    --display: grid;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    --grid-auto-flow: row;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-01e85c9
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-01e85c9 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-01e85c9
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-01e85c9
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-01e85c9
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120
    .elementor-element.elementor-element-b9684d2
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-b9684d2 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-b9684d2
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-b9684d2
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-b9684d2
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5ca1f54
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5ca1f54 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5ca1f54
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-5ca1f54
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5ca1f54
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1cd5add
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-1cd5add {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1cd5add
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-1cd5add
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-1cd5add
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120
    .elementor-element.elementor-element-cde11b9
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-cde11b9 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-cde11b9
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-cde11b9
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-cde11b9
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120
    .elementor-element.elementor-element-fa6d5d4
    .elementor-icon-list-icon
    i {
    color: #000000;
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-fa6d5d4 {
    --e-icon-list-icon-size: 20px;
    --e-icon-list-icon-align: left;
    --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
      0 0;
    --icon-vertical-align: flex-start;
    --icon-vertical-offset: 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-fa6d5d4
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
    font-weight: 500;
  }
  .elementor-34120
    .elementor-element.elementor-element-fa6d5d4
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-fa6d5d4
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 14px 14px 14px 14px;
  }
  .elementor-34120 .elementor-element.elementor-element-7eb9b70 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-7eb9b70
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-6192d6a
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-6192d6a
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-6192d6a
    .elementor-icon-list-icon
    i {
    color: var(--e-global-color-f229bad);
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-6192d6a {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6192d6a
    .elementor-icon-list-item
    > .elementor-icon-list-text {
    font-size: 21px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6192d6a
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-12cc36e {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-12cc36e
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-6ed2e02 {
    --display: grid;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(1, 1fr);
    --grid-auto-flow: row;
    --background-transition: 0.3s;
    --padding-block-start: 10px;
    --padding-block-end: 10px;
    --padding-inline-start: 10px;
    --padding-inline-end: 10px;
  }
  .elementor-34120 .elementor-element.elementor-element-41a7e73 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-423987b img {
    height: 100px;
    object-fit: contain;
  }
  .elementor-34120 .elementor-element.elementor-element-740f577 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-740f577
    .elementor-heading-title {
    color: #ff0202;
  }
  .elementor-34120
    .elementor-element.elementor-element-740f577
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-47b5cf9 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-f6f3609 {
    text-align: left;
  }
  .elementor-34120 .elementor-element.elementor-element-f6f3609 img {
    height: 100px;
  }
  .elementor-34120 .elementor-element.elementor-element-1c03535 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-1c03535
    .elementor-heading-title {
    color: #ff0202;
  }
  .elementor-34120
    .elementor-element.elementor-element-1c03535
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-5cf05c6 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-f435e7a img {
    height: 100px;
    object-fit: contain;
  }
  .elementor-34120 .elementor-element.elementor-element-07e7fb5 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-07e7fb5
    .elementor-heading-title {
    color: #ff0202;
  }
  .elementor-34120
    .elementor-element.elementor-element-07e7fb5
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-ae2cf74 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-ae2cf74
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-0ab9a9d {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-7740753 img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-7740753 {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-c58147d img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-c58147d {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-8738b35 img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-8738b35 {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-62b4861 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-62b4861
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-24df401
    .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor4);
    color: var(--e-global-color-astglobalcolor4);
    background-color: var(--e-global-color-d384b9c);
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-24df401
    > .elementor-widget-container {
    margin: 20px 0px 20px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-62f4833a {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --padding-block-start: 50px;
    --padding-block-end: 50px;
    --padding-inline-start: 20px;
    --padding-inline-end: 20px;
  }
  .elementor-34120 .elementor-element.elementor-element-5e05fb00 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-5e05fb00
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-f03afcf {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-a01f022 img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-a01f022 {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-2bff6af img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-2bff6af {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-45f5d19 img {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
  }
  .elementor-34120 .elementor-element.elementor-element-45f5d19 {
    width: var(--container-widget-width, 33%);
    max-width: 33%;
    --container-widget-width: 33%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-21f26c3 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-21f26c3
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-1a046b4e {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1a046b4e
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-1a046b4e
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-556a31d7 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-556a31d7:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #fcfcfc;
  }
  .elementor-34120 .elementor-element.elementor-element-556a31d7,
  .elementor-34120 .elementor-element.elementor-element-556a31d7::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-59e2d7ec {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-59e2d7ec
    .elementor-heading-title {
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-59e2d7ec
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-1b42c64a {
    text-align: center;
    font-weight: 600;
  }
  .elementor-34120
    .elementor-element.elementor-element-1b42c64a
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-57e2f148 {
    --display: grid;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    --grid-auto-flow: row;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
    --padding-block-start: 0px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1e2be722
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-1e2be722.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-1e2be722 {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1e2be722
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-3010009b
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-3010009b.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-3010009b {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3010009b
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-506a87d1
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-506a87d1.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-506a87d1 {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-506a87d1
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-6352ea31
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-6352ea31.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-6352ea31 {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6352ea31
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-3b698f96
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-3b698f96.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-3b698f96 {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3b698f96
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-5b5514f2
    .elementor-icon-box-wrapper {
    text-align: left;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b5514f2.elementor-view-default
    .elementor-icon {
    fill: #0077b5;
    color: #0077b5;
    border-color: #0077b5;
  }
  .elementor-34120 .elementor-element.elementor-element-5b5514f2 {
    --icon-box-icon-margin: 15px;
  }
  .elementor-34120
    .elementor-element.elementor-element-5b5514f2
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120 .elementor-element.elementor-element-320f6215 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-16f2ae1c {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-16f2ae1c
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-16f2ae1c
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-5fabf8ce {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --gap: 10px 10px;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5fabf8ce:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor2);
  }
  .elementor-34120 .elementor-element.elementor-element-5fabf8ce,
  .elementor-34120 .elementor-element.elementor-element-5fabf8ce::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-d024c6 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-d024c6
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor5);
  }
  .elementor-34120
    .elementor-element.elementor-element-d024c6
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-7180f128 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-7180f128
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor0);
  }
  .elementor-34120
    .elementor-element.elementor-element-7180f128
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-5669126b {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --padding-block-start: 50px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-2e54b50c {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120 .elementor-element.elementor-element-50486b93 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --align-items: center;
    --flex-wrap: nowrap;
    --background-transition: 0.3s;
    --border-radius: 8px 8px 8px 8px;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120
    .elementor-element.elementor-element-50486b93:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120 .elementor-element.elementor-element-50486b93,
  .elementor-34120 .elementor-element.elementor-element-50486b93::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-51666b8b {
    text-align: center;
    width: var(--container-widget-width, 40%);
    max-width: 40%;
    --container-widget-width: 40%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-51666b8b
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor0);
  }
  .elementor-34120
    .elementor-element.elementor-element-51666b8b
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-51666b8b
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 5px 0px 020px 0px;
    background-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-1231cc3f
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120 .elementor-element.elementor-element-640026f7 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-640026f7
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-52b52bd9 {
    text-align: center;
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-52b52bd9
    .elementor-heading-title {
    font-weight: 600;
  }
  .elementor-34120
    .elementor-element.elementor-element-52b52bd9
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-52b52bd9
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 10px 20px 10px 20px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120 .elementor-element.elementor-element-73c99aa1 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --align-items: center;
    --flex-wrap: nowrap;
    --background-transition: 0.3s;
    --border-radius: 8px 8px 8px 8px;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120
    .elementor-element.elementor-element-73c99aa1:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120 .elementor-element.elementor-element-73c99aa1,
  .elementor-34120 .elementor-element.elementor-element-73c99aa1::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-6000711c {
    text-align: center;
    width: var(--container-widget-width, 40%);
    max-width: 40%;
    --container-widget-width: 40%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-6000711c
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor0);
  }
  .elementor-34120
    .elementor-element.elementor-element-6000711c
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-6000711c
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 5px 0px 20px 0px;
    background-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7125d00a
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120 .elementor-element.elementor-element-29530e3d {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-29530e3d
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-7da2ef8c {
    text-align: center;
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-7da2ef8c
    .elementor-heading-title {
    font-weight: 600;
  }
  .elementor-34120
    .elementor-element.elementor-element-7da2ef8c
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-7da2ef8c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 10px 20px 10px 20px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120 .elementor-element.elementor-element-1c95162e {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --align-items: center;
    --flex-wrap: nowrap;
    --background-transition: 0.3s;
    --border-radius: 8px 8px 8px 8px;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120
    .elementor-element.elementor-element-1c95162e:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120 .elementor-element.elementor-element-1c95162e,
  .elementor-34120 .elementor-element.elementor-element-1c95162e::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-49a40ab5 {
    text-align: center;
    width: var(--container-widget-width, 40%);
    max-width: 40%;
    --container-widget-width: 40%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-49a40ab5
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor0);
  }
  .elementor-34120
    .elementor-element.elementor-element-49a40ab5
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-49a40ab5
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 5px 0px 20px 0px;
    background-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-754d47b0
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120 .elementor-element.elementor-element-76413316 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-76413316
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-3c0b87dd {
    text-align: center;
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-3c0b87dd
    .elementor-heading-title {
    font-weight: 600;
  }
  .elementor-34120
    .elementor-element.elementor-element-3c0b87dd
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-3c0b87dd
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 10px 20px 10px 20px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-astglobalcolor2);
    border-radius: 5px 5px 5px 5px;
  }
  .elementor-34120 .elementor-element.elementor-element-3c8e51b {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-3c8e51b
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-c0e8449
    .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor4);
    color: var(--e-global-color-astglobalcolor4);
    background-color: var(--e-global-color-d384b9c);
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-c0e8449
    > .elementor-widget-container {
    margin: 20px 0px 20px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-69b76a73 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: center;
    --flex-wrap: wrap;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-6b02acc5 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-6b02acc5
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e2df06
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e2df06.elementor-view-default
    .elementor-icon {
    color: #ffd700;
    border-color: #ffd700;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e2df06
    .elementor-icon {
    font-size: 40px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e2df06.elementor-element {
    --align-self: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-4718949
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-4718949.elementor-view-default
    .elementor-icon {
    color: #ffd700;
    border-color: #ffd700;
  }
  .elementor-34120
    .elementor-element.elementor-element-4718949
    .elementor-icon {
    font-size: 40px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4718949.elementor-element {
    --align-self: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-e424a27
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-e424a27.elementor-view-default
    .elementor-icon {
    color: #ffd700;
    border-color: #ffd700;
  }
  .elementor-34120
    .elementor-element.elementor-element-e424a27
    .elementor-icon {
    font-size: 40px;
  }
  .elementor-34120
    .elementor-element.elementor-element-e424a27.elementor-element {
    --align-self: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-ed34601
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-ed34601.elementor-view-default
    .elementor-icon {
    color: #ffd700;
    border-color: #ffd700;
  }
  .elementor-34120
    .elementor-element.elementor-element-ed34601
    .elementor-icon {
    font-size: 40px;
  }
  .elementor-34120
    .elementor-element.elementor-element-ed34601.elementor-element {
    --align-self: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-e2f2532
    .elementor-icon-wrapper {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-e2f2532.elementor-view-default
    .elementor-icon {
    color: #ffd700;
    border-color: #ffd700;
  }
  .elementor-34120
    .elementor-element.elementor-element-e2f2532
    .elementor-icon {
    font-size: 40px;
  }
  .elementor-34120
    .elementor-element.elementor-element-e2f2532.elementor-element {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-215d9761 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-215d9761
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-global-30376
    .elementor-countdown-wrapper {
    max-width: 60%;
  }
  .elementor-34120
    .elementor-element.elementor-global-30376
    .elementor-countdown-item {
    background-color: var(--e-global-color-astglobalcolor2);
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-global-30376
    .elementor-countdown-item:not(:first-of-type) {
    margin-left: calc(10px / 2);
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-global-30376
    .elementor-countdown-item:not(:last-of-type) {
    margin-right: calc(10px / 2);
  }
  .elementor-34120 .elementor-element.elementor-element-cbd4117 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-4932b94f {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4932b94f
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-4932b94f
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-5c5082a1 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-5c5082a1:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor2);
  }
  .elementor-34120 .elementor-element.elementor-element-5c5082a1,
  .elementor-34120 .elementor-element.elementor-element-5c5082a1::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5c5082a1.e-con {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-765aa2b3 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-765aa2b3
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor6);
  }
  .elementor-34120
    .elementor-element.elementor-element-765aa2b3
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-42459eb4 {
    text-align: center;
    color: var(--e-global-color-astglobalcolor5);
  }
  .elementor-34120
    .elementor-element.elementor-element-42459eb4
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-42459eb4
    .elementor-widget-container
    p:last-child {
    margin-bottom: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-57653340 img {
    border-radius: 12px 12px 12px 12px;
  }
  .elementor-34120
    .elementor-element.elementor-element-57653340
    > .elementor-widget-container {
    margin: 0em 10em 0em 010em;
  }
  .elementor-34120
    .elementor-element.elementor-element-2ba3931
    .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor4);
    color: var(--e-global-color-astglobalcolor4);
    background-color: var(--e-global-color-d384b9c);
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-2ba3931
    > .elementor-widget-container {
    margin: 20px 0px 20px 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-27c4952b {
    --divider-border-style: zigzag;
    --divider-color: #ffffff;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-27c4952b
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-27c4952b
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-4f273fea {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-71c71b2e {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-71c71b2e
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-593c64e5 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-2ed69ece {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120 .elementor-element.elementor-element-15623e4 {
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-15623e4
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-50ef9674 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: space-between;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
    --padding-block-start: 0px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-7a9875b2
    .elementor-icon-list-icon
    i {
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-7a9875b2 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
    width: initial;
    max-width: initial;
  }
  .elementor-34120
    .elementor-element.elementor-element-7a9875b2
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-7a9875b2.elementor-element {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-62450334
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-62450334
    .elementor-widget-container
    p:last-child {
    margin-bottom: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-3633a21c {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-6a5c5f67 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
  }
  .elementor-34120 .elementor-element.elementor-element-7d784349 {
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120
    .elementor-element.elementor-element-7d784349
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-26c4f2df {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --container-widget-height: 100%;
    --container-widget-flex-grow: 1;
    --container-widget-align-self: stretch;
    --justify-content: space-between;
    --background-transition: 0.3s;
    --margin-block-start: 0rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem;
    --padding-block-start: 0px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-77e02a52
    .elementor-icon-list-icon
    i {
    transition: color 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-77e02a52 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
    width: initial;
    max-width: initial;
  }
  .elementor-34120
    .elementor-element.elementor-element-77e02a52
    .elementor-icon-list-text {
    transition: color 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-77e02a52.elementor-element {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-746d2702
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-746d2702
    .elementor-widget-container
    p:last-child {
    margin-bottom: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-8ab0d6b {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --padding-block-start: 20px;
    --padding-block-end: 20px;
    --padding-inline-start: 20px;
    --padding-inline-end: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-8ab0d6b:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor1);
  }
  .elementor-34120 .elementor-element.elementor-element-8ab0d6b,
  .elementor-34120 .elementor-element.elementor-element-8ab0d6b::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-ee292a9 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-ee292a9
    .elementor-heading-title {
    color: #ffffff;
    font-size: 32px;
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-ee292a9
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-34fe193 {
    --e-image-carousel-slides-to-show: 5;
    width: var(--container-widget-width, 75%);
    max-width: 75%;
    --container-widget-width: 75%;
    --container-widget-flex-grow: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-34fe193
    .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-34fe193
    .elementor-image-carousel-wrapper
    .elementor-image-carousel
    .swiper-slide-image {
    border-radius: 20px 20px 20px 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-34fe193
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-34fe193.elementor-element {
    --align-self: center;
  }
  .elementor-34120 .elementor-element.elementor-element-a93f697 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120
    .elementor-element.elementor-element-a93f697:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #fcfcfc;
  }
  .elementor-34120 .elementor-element.elementor-element-a93f697,
  .elementor-34120 .elementor-element.elementor-element-a93f697::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-33899acf {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-33899acf
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-33899acf
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-1c8f7aae {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-1c8f7aae
    .elementor-heading-title {
    font-size: 32px;
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-1c8f7aae
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120 .elementor-element.elementor-element-548e93a {
    text-align: center;
    font-weight: 600;
  }
  .elementor-34120
    .elementor-element.elementor-element-548e93a
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-548e93a
    .elementor-widget-container
    p:last-child {
    margin-bottom: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-14022500 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-587d038b {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-587d038b
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-587d038b
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-43070bc7 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-43070bc7
    .elementor-heading-title {
    font-size: 32px;
    text-transform: uppercase;
  }
  .elementor-34120
    .elementor-element.elementor-element-43070bc7
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-4b90508a
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-4b90508a
    .elementor-tab-content {
    padding: 10px 20px 0px 20px;
  }
  .elementor-34120 .elementor-element.elementor-element-c09d510 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-3fadf00e {
    --divider-border-style: zigzag;
    --divider-color: #000;
    --divider-border-width: 1.4px;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
  }
  .elementor-34120
    .elementor-element.elementor-element-3fadf00e
    .elementor-divider-separator {
    width: 42%;
    margin: 0 auto;
    margin-center: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-3fadf00e
    .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .elementor-34120 .elementor-element.elementor-element-e076dfb {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-4ff34ef {
    --image-transition-duration: 800ms;
    --overlay-transition-duration: 800ms;
    --content-text-align: center;
    --content-padding: 20px;
    --content-transition-duration: 800ms;
    --content-transition-delay: 800ms;
  }
  .elementor-34120
    .elementor-element.elementor-element-4ff34ef
    .e-gallery-item:hover
    .elementor-gallery-item__overlay,
  .elementor-34120
    .elementor-element.elementor-element-4ff34ef
    .e-gallery-item:focus
    .elementor-gallery-item__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .elementor-34120 .elementor-element.elementor-element-db0973f {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-82a244f {
    --image-transition-duration: 800ms;
    --overlay-transition-duration: 800ms;
    --content-text-align: center;
    --content-padding: 20px;
    --content-transition-duration: 800ms;
    --content-transition-delay: 800ms;
  }
  .elementor-34120
    .elementor-element.elementor-element-82a244f
    .e-gallery-item:hover
    .elementor-gallery-item__overlay,
  .elementor-34120
    .elementor-element.elementor-element-82a244f
    .e-gallery-item:focus
    .elementor-gallery-item__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .elementor-34120 .elementor-element.elementor-element-26618fb {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-19fdacb {
    --image-transition-duration: 800ms;
    --overlay-transition-duration: 800ms;
    --content-text-align: center;
    --content-padding: 20px;
    --content-transition-duration: 800ms;
    --content-transition-delay: 800ms;
  }
  .elementor-34120
    .elementor-element.elementor-element-19fdacb
    .e-gallery-item:hover
    .elementor-gallery-item__overlay,
  .elementor-34120
    .elementor-element.elementor-element-19fdacb
    .e-gallery-item:focus
    .elementor-gallery-item__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .elementor-34120 .elementor-element.elementor-element-18c2ad9 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-34120 .elementor-element.elementor-element-6a821183 {
    --display: grid;
    --min-height: 0px;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(1, 1fr);
    --grid-auto-flow: row;
    --background-transition: 0.3s;
    --padding-block-start: 10px;
    --padding-block-end: 10px;
    --padding-inline-start: 10px;
    --padding-inline-end: 10px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6a821183:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: var(--e-global-color-astglobalcolor2);
  }
  .elementor-34120 .elementor-element.elementor-element-6a821183,
  .elementor-34120 .elementor-element.elementor-element-6a821183::before {
    --border-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-529015a4 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: calc(
      (1 - var(--container-widget-flex-grow)) * 100%
    );
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --justify-content: flex-start;
    --align-items: flex-start;
    --gap: 0px 0px;
    --background-transition: 0.3s;
    --padding-block-start: 0px;
    --padding-block-end: 0px;
    --padding-inline-start: 0px;
    --padding-inline-end: 0px;
  }
  .elementor-34120 .elementor-element.elementor-element-529015a4.e-con {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-ebfa075
    .elementor-heading-title {
    color: var(--e-global-color-d384b9c);
  }
  .elementor-34120
    .elementor-element.elementor-element-ebfa075
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-157b980a
    .elementor-heading-title {
    color: #ffffff;
    text-decoration: line-through;
  }
  .elementor-34120
    .elementor-element.elementor-element-157b980a
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-157b980a.elementor-element {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-5149a36b {
    --display: flex;
    --background-transition: 0.3s;
  }
  .elementor-34120 .elementor-element.elementor-element-5149a36b.e-con {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120 .elementor-element.elementor-element-512dd1d4 {
    text-align: center;
  }
  .elementor-34120
    .elementor-element.elementor-element-512dd1d4
    .elementor-heading-title {
    color: var(--e-global-color-astglobalcolor5);
  }
  .elementor-34120
    .elementor-element.elementor-element-512dd1d4
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  .elementor-34120
    .elementor-element.elementor-element-399178c8
    .elementor-countdown-wrapper {
    max-width: 100%;
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-element-399178c8
    .elementor-countdown-item:not(:first-of-type) {
    margin-left: calc(10px / 2);
  }
  body:not(.rtl)
    .elementor-34120
    .elementor-element.elementor-element-399178c8
    .elementor-countdown-item:not(:last-of-type) {
    margin-right: calc(10px / 2);
  }
  .elementor-34120
    .elementor-element.elementor-element-399178c8
    .elementor-countdown-item {
    padding: 0px 0px 0px 0px;
  }
  .elementor-34120
    .elementor-element.elementor-element-399178c8
    .elementor-countdown-digits {
    font-size: 35px;
  }
  .elementor-34120
    .elementor-element.elementor-element-399178c8.elementor-element {
    --flex-grow: 0;
    --flex-shrink: 0;
  }
  .elementor-34120
    .elementor-element.elementor-element-104a13c
    .elementor-button {
    fill: #ffffff;
    color: #ffffff;
    background-color: var(--e-global-color-d384b9c);
  }
  @media (max-width: 1024px) {
    .elementor-34120 .elementor-element.elementor-element-ab11b67 {
      --grid-auto-flow: row;
    }
    .elementor-34120 .elementor-element.elementor-element-6ed2e02 {
      --grid-auto-flow: row;
    }
    .elementor-34120 .elementor-element.elementor-element-57e2f148 {
      --grid-auto-flow: row;
    }
    .elementor-34120 .elementor-element.elementor-element-6a821183 {
      --grid-auto-flow: row;
    }
  }
  @media (max-width: 767px) {
    .elementor-34120 .elementor-element.elementor-element-1d04c83b {
      --gap: 20px 20px;
      --margin-block-start: 0px;
      --margin-block-end: 0px;
      --margin-inline-start: 0px;
      --margin-inline-end: 0px;
      --padding-block-start: 4rem;
      --padding-block-end: 2rem;
      --padding-inline-start: 00.7rem;
      --padding-inline-end: 00.7rem;
    }
    .elementor-34120 .elementor-element.elementor-element-def4128 {
      text-align: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-def4128
      .elementor-heading-title {
      font-size: 36px;
    }
    .elementor-34120
      .elementor-element.elementor-element-def4128
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-34120 .elementor-element.elementor-element-6a465618 {
      text-align: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-28e0a9b
      .elementor-heading-title {
      font-size: 20px;
    }
    .elementor-34120 .elementor-element.elementor-element-efb5660 {
      --padding-block-start: 5px;
      --padding-block-end: 5px;
      --padding-inline-start: 5px;
      --padding-inline-end: 5px;
    }
    .elementor-34120 .elementor-element.elementor-element-1414598f {
      --justify-content: space-around;
      --gap: 10px 10px;
      --flex-wrap: wrap;
      --border-radius: 8px 8px 8px 8px;
      --padding-block-start: 10px;
      --padding-block-end: 10px;
      --padding-inline-start: 5px;
      --padding-inline-end: 5px;
    }
    .elementor-34120
      .elementor-element.elementor-element-5da74196
      > .elementor-widget-container {
      padding: 5px 5px 5px 5px;
      border-radius: 7px 7px 7px 7px;
    }
    .elementor-34120 .elementor-element.elementor-element-5da74196 {
      width: var(--container-widget-width, 48%);
      max-width: 48%;
      --container-widget-width: 48%;
      --container-widget-flex-grow: 0;
    }
    .elementor-34120
      .elementor-element.elementor-element-7f107895
      > .elementor-widget-container {
      padding: 5px 5px 5px 5px;
      border-radius: 7px 7px 7px 7px;
    }
    .elementor-34120 .elementor-element.elementor-element-7f107895 {
      width: var(--container-widget-width, 48%);
      max-width: 48%;
      --container-widget-width: 48%;
      --container-widget-flex-grow: 0;
    }
    .elementor-34120
      .elementor-element.elementor-element-2bae90d4
      > .elementor-widget-container {
      padding: 5px 5px 5px 5px;
      border-radius: 7px 7px 7px 7px;
    }
    .elementor-34120 .elementor-element.elementor-element-2bae90d4 {
      width: var(--container-widget-width, 48%);
      max-width: 48%;
      --container-widget-width: 48%;
      --container-widget-flex-grow: 0;
    }
    .elementor-34120
      .elementor-element.elementor-element-385bc16
      > .elementor-widget-container {
      padding: 5px 5px 5px 5px;
      border-radius: 7px 7px 7px 7px;
    }
    .elementor-34120 .elementor-element.elementor-element-385bc16 {
      width: var(--container-widget-width, 48%);
      max-width: 48%;
      --container-widget-width: 48%;
      --container-widget-flex-grow: 0;
    }
    .elementor-34120
      .elementor-element.elementor-element-4e9bd7a9
      .elementor-icon-list-items:not(.elementor-inline-items)
      .elementor-icon-list-item:not(:last-child) {
      padding-bottom: calc(12px / 2);
    }
    .elementor-34120
      .elementor-element.elementor-element-4e9bd7a9
      .elementor-icon-list-items:not(.elementor-inline-items)
      .elementor-icon-list-item:not(:first-child) {
      margin-top: calc(12px / 2);
    }
    .elementor-34120
      .elementor-element.elementor-element-4e9bd7a9
      .elementor-icon-list-item
      .elementor-icon-list-icon {
      margin-top: 1px;
    }
    .elementor-34120
      .elementor-element.elementor-element-4e9bd7a9
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 16px;
    }
    .elementor-34120 .elementor-element.elementor-element-4e9bd7a9 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-d01881d {
      --padding-block-start: 0px;
      --padding-block-end: 0px;
      --padding-inline-start: 0px;
      --padding-inline-end: 0px;
    }
    .elementor-34120 .elementor-element.elementor-element-d01881d.e-con {
      --order: -99999;
    }
    .elementor-34120 .elementor-element.elementor-element-5a9332bf img {
      border-radius: 10px 10px 10px 10px;
    }
    .elementor-34120 .elementor-element.elementor-element-5a9332bf {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-element-5a9332bf.elementor-element {
      --order: -99999;
    }
    .elementor-34120
      .elementor-element.elementor-element-6341348
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-271fdad8
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
    .elementor-34120 .elementor-element.elementor-element-23e779ed {
      text-align: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-23e779ed
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120 .elementor-element.elementor-element-04f7fb3 {
      text-align: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-04f7fb3
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120 .elementor-element.elementor-element-d19a963 {
      --e-image-carousel-slides-to-show: 3;
    }
    .elementor-34120 .elementor-element.elementor-element-15ed511e {
      --padding-block-start: 1rem;
      --padding-block-end: 1rem;
      --padding-inline-start: 1rem;
      --padding-inline-end: 1rem;
    }
    .elementor-34120 .elementor-element.elementor-element-12cfefcb {
      --padding-block-start: 40px;
      --padding-block-end: 40px;
      --padding-inline-start: 20px;
      --padding-inline-end: 20px;
    }
    .elementor-34120 .elementor-element.elementor-element-545ca707 img {
      width: 150px;
    }
    .elementor-34120
      .elementor-element.elementor-element-582c6669
      .elementor-star-rating {
      font-size: 30px;
    }
    .elementor-34120
      .elementor-element.elementor-element-6f911e49
      .elementor-testimonial__content {
      padding: 0px 0px 0px 0px;
    }
    .elementor-34120
      .elementor-element.elementor-element-6f911e49.elementor-testimonial--layout-image_above
      .elementor-testimonial__footer {
      padding: 0 0px 0 0px;
    }
    .elementor-34120 .elementor-element.elementor-element-5843406 {
      --padding-block-start: 1rem;
      --padding-block-end: 1rem;
      --padding-inline-start: 1rem;
      --padding-inline-end: 1rem;
    }
    .elementor-34120
      .elementor-element.elementor-element-f65e5f0
      .elementor-heading-title {
      font-size: 21px;
    }
    .elementor-34120
      .elementor-element.elementor-element-65c84b0
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120 .elementor-element.elementor-element-ab11b67 {
      --e-con-grid-template-columns: repeat(1, 1fr);
      --grid-auto-flow: row;
    }
    .elementor-34120
      .elementor-element.elementor-element-01e85c9
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-b9684d2
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-5ca1f54
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-1cd5add
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-cde11b9
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-fa6d5d4
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-6192d6a
      .elementor-icon-list-items:not(.elementor-inline-items)
      .elementor-icon-list-item:not(:last-child) {
      padding-bottom: calc(10px / 2);
    }
    .elementor-34120
      .elementor-element.elementor-element-6192d6a
      .elementor-icon-list-items:not(.elementor-inline-items)
      .elementor-icon-list-item:not(:first-child) {
      margin-top: calc(10px / 2);
    }
    .elementor-34120
      .elementor-element.elementor-element-6192d6a
      .elementor-icon-list-item
      > .elementor-icon-list-text {
      font-size: 16px;
    }
    .elementor-34120
      .elementor-element.elementor-element-6192d6a
      > .elementor-widget-container {
      padding: 10px 10px 10px 10px;
    }
    .elementor-34120 .elementor-element.elementor-element-6ed2e02 {
      --e-con-grid-template-columns: repeat(1, 1fr);
      --grid-auto-flow: row;
    }
    .elementor-34120 .elementor-element.elementor-element-423987b img {
      height: 90px;
      object-fit: contain;
    }
    .elementor-34120 .elementor-element.elementor-element-f6f3609 img {
      height: 90px;
      object-fit: contain;
    }
    .elementor-34120 .elementor-element.elementor-element-f435e7a img {
      height: 90px;
    }
    .elementor-34120 .elementor-element.elementor-element-7740753 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-c58147d {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-8738b35 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-element-24df401
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-24df401
      > .elementor-widget-container {
      padding: 0px 10px 0px 10px;
    }
    .elementor-34120 .elementor-element.elementor-element-a01f022 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-2bff6af {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-45f5d19 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-57e2f148 {
      --e-con-grid-template-columns: repeat(2, 1fr);
      --grid-auto-flow: row;
    }
    .elementor-34120 .elementor-element.elementor-element-1e2be722 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-3010009b {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-506a87d1 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-6352ea31 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-3b698f96 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-5b5514f2 {
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-element-c0e8449
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-c0e8449
      > .elementor-widget-container {
      padding: 0px 10px 0px 10px;
    }
    .elementor-34120
      .elementor-element.elementor-global-30376
      .elementor-countdown-wrapper {
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-global-30376
      .elementor-countdown-item {
      padding: 12px 12px 12px 12px;
    }
    .elementor-34120
      .elementor-element.elementor-global-30376
      .elementor-countdown-digits {
      font-size: 36px;
    }
    .elementor-34120
      .elementor-element.elementor-global-30376
      .elementor-countdown-label {
      font-size: 16px;
    }
    .elementor-34120
      .elementor-element.elementor-global-30376
      > .elementor-widget-container {
      padding: 5px 5px 5px 5px;
    }
    .elementor-34120
      .elementor-element.elementor-element-57653340
      > .elementor-widget-container {
      margin: 0em 0em 0em 0em;
    }
    .elementor-34120
      .elementor-element.elementor-element-2ba3931
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
    .elementor-34120
      .elementor-element.elementor-element-2ba3931
      > .elementor-widget-container {
      padding: 0px 10px 0px 10px;
    }
    .elementor-34120
      .elementor-element.elementor-element-ee292a9
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120 .elementor-element.elementor-element-34fe193 {
      --e-image-carousel-slides-to-show: 4;
    }
    .elementor-34120
      .elementor-element.elementor-element-34fe193
      .elementor-image-carousel-wrapper
      .elementor-image-carousel
      .swiper-slide-image {
      border-radius: 50px 50px 50px 50px;
    }
    .elementor-34120
      .elementor-element.elementor-element-1c8f7aae
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120
      .elementor-element.elementor-element-43070bc7
      .elementor-heading-title {
      font-size: 18px;
    }
    .elementor-34120 .elementor-element.elementor-element-6a821183 {
      --e-con-grid-template-columns: repeat(3, 1fr);
      --e-con-grid-template-rows: repeat(1, 1fr);
      --gap: 0px 0px;
      --grid-auto-flow: row;
      --align-items: center;
      --margin-block-start: 0px;
      --margin-block-end: 0px;
      --margin-inline-start: 0px;
      --margin-inline-end: 0px;
      --padding-block-start: 0px;
      --padding-block-end: 0px;
      --padding-inline-start: 0px;
      --padding-inline-end: 0px;
      --z-index: 100;
    }
    .elementor-34120 .elementor-element.elementor-element-529015a4 {
      --flex-direction: column;
      --container-widget-width: calc(
        (1 - var(--container-widget-flex-grow)) * 100%
      );
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --justify-content: center;
      --align-items: flex-start;
      --gap: 0px 0px;
      --flex-wrap: wrap;
      --padding-block-start: 5px;
      --padding-block-end: 5px;
      --padding-inline-start: 5px;
      --padding-inline-end: 5px;
    }
    .elementor-34120 .elementor-element.elementor-element-ebfa075 {
      text-align: center;
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-element-ebfa075.elementor-element {
      --align-self: flex-end;
    }
    .elementor-34120 .elementor-element.elementor-element-157b980a {
      text-align: center;
      width: 100%;
      max-width: 100%;
    }
    .elementor-34120
      .elementor-element.elementor-element-157b980a
      .elementor-heading-title {
      font-size: 17px;
    }
    .elementor-34120
      .elementor-element.elementor-element-157b980a
      > .elementor-widget-container {
      padding: 0px 0px 0px 0px;
    }
    .elementor-34120 .elementor-element.elementor-element-5149a36b {
      --flex-direction: column;
      --container-widget-width: 100%;
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --justify-content: center;
      --gap: 0px 0px;
    }
    .elementor-34120 .elementor-element.elementor-element-512dd1d4 {
      text-align: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-512dd1d4
      .elementor-heading-title {
      font-size: 14px;
    }
    .elementor-34120
      .elementor-element.elementor-element-512dd1d4.elementor-element {
      --align-self: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-wrapper {
      max-width: 100%;
    }
    body:not(.rtl)
      .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-item:not(:first-of-type) {
      margin-left: calc(5px / 2);
    }
    body:not(.rtl)
      .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-item:not(:last-of-type) {
      margin-right: calc(5px / 2);
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-item {
      padding: 0px 0px 0px 0px;
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-digits {
      font-size: 16px;
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8
      .elementor-countdown-label {
      font-size: 12px;
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8
      > .elementor-widget-container {
      margin: 0px 0px 0px 4px;
    }
    .elementor-34120
      .elementor-element.elementor-element-399178c8.elementor-element {
      --align-self: center;
    }
    .elementor-34120
      .elementor-element.elementor-element-104a13c
      .elementor-button {
      padding: 10px 15px 10px 015px;
    }
    .elementor-34120 .elementor-element.elementor-element-104a13c {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 768px) {
    .elementor-34120 .elementor-element.elementor-element-784e9534 {
      --width: 46.964%;
    }
    .elementor-34120 .elementor-element.elementor-element-73be4078 {
      --width: 90%;
    }
    .elementor-34120 .elementor-element.elementor-element-457cd71 {
      --width: 30%;
    }
    .elementor-34120 .elementor-element.elementor-element-4791031d {
      --width: 100%;
    }
    .elementor-34120 .elementor-element.elementor-element-618e1f8e {
      --width: 75%;
    }
    .elementor-34120 .elementor-element.elementor-element-5c5082a1 {
      --width: 99.509%;
    }
    .elementor-34120 .elementor-element.elementor-element-a93f697 {
      --content-width: 950px;
    }
    .elementor-34120 .elementor-element.elementor-element-6a821183 {
      --width: 100%;
    }
  }
  .elementor-34120 .elementor-element.elementor-element-def4128 u {
    color: black;
    text-underline-offset: 5px;
  }
  .elementor-34120 .elementor-element.elementor-element-28e0a9b mark {
    background-color: #ff6d07;
    color: white;
  }
  .elementor-34120
    .elementor-element.elementor-element-4e9bd7a9
    .fa-long-arrow-alt-right {
    background-color: white;
    padding: 2px 3px;
    border-radius: 50%;
  }
  .elementor-34120 .elementor-element.elementor-element-4e9bd7a9 span {
    color: #ff6d07;
    font-weight: bold;
  }
  .elementor-34120 .elementor-element.elementor-element-23e779ed span {
    color: #fff015;
  }
  .elementor-34120
    .elementor-element.elementor-element-d19a963
    .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
  .elementor-testimonial__name {
    margin-top: -5px;
  }
  .elementor-34120
    .elementor-element.elementor-element-6f911e49
    .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  .elementor-34120
    .elementor-element.elementor-element-618e1f8e
    .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  .elementor-34120
    .elementor-element.elementor-element-12cfefcb
    .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  .elementor-34120 .elementor-element.elementor-element-01e85c9 {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-b9684d2 {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-5ca1f54 {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-1cd5add {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-cde11b9 {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-fa6d5d4 {
    display: grid;
  }
  .elementor-34120 .elementor-element.elementor-element-42459eb4 strong {
    color: var(--e-global-color-astglobalcolor0);
  }
  .elementor-34120
    .elementor-element.elementor-element-34fe193
    .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
  .elementor-34120 .elementor-element.elementor-element-34fe193 img {
    background-color: white;
  }
  body.elementor-page-34120 u {
    color: #ff6d07;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3 ; media=all */
@media all {
  .fab,
  .far,
  .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .fa-angle-double-right:before {
    content: "\f101";
  }
  .fa-calendar-check:before {
    content: "\f274";
  }
  .fa-caret-right:before {
    content: "\f0da";
  }
  .fa-caret-up:before {
    content: "\f0d8";
  }
  .fa-check:before {
    content: "\f00c";
  }
  .fa-check-circle:before {
    content: "\f058";
  }
  .fa-clock:before {
    content: "\f017";
  }
  .fa-hourglass:before {
    content: "\f254";
  }
  .fa-linkedin:before {
    content: "\f08c";
  }
  .fa-long-arrow-alt-right:before {
    content: "\f30b";
  }
  .fa-star:before {
    content: "\f005";
  }
  .fa-star-half-alt:before {
    content: "\f5c0";
  }
  .fa-video:before {
    content: "\f03d";
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3 ; media=all */
@media all {
  .fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/css/regular.min.css?ver=5.15.3 ; media=all */
@media all {
  .far {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min.css?ver=5.15.3 ; media=all */
@media all {
  .fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: 400;
  }
}
/*! CSS Used from: Embedded */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
/*! CSS Used from: Embedded */
.elementor-widget-image {
  text-align: center;
}
.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css */
.elementor-widget.elementor-icon-list--layout-inline
  .elementor-widget-container {
  overflow: hidden;
}
.elementor-widget .elementor-icon-list-items.elementor-inline-items {
  margin-right: -8px;
  margin-left: -8px;
}
.elementor-widget
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: 8px;
  margin-left: 8px;
}
.elementor-widget
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  width: auto;
  left: auto;
  right: auto;
  position: relative;
  height: 100%;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left-width: 1px;
  border-style: solid;
  right: -8px;
}
.elementor-widget .elementor-icon-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.elementor-widget .elementor-icon-list-item {
  margin: 0;
  padding: 0;
  position: relative;
}
.elementor-widget .elementor-icon-list-item:after {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.elementor-widget .elementor-icon-list-item {
  display: flex;
  font-size: inherit;
  align-items: var(--icon-vertical-align, center);
}
.elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text {
  align-self: center;
  padding-left: 5px;
}
.elementor-widget .elementor-icon-list-icon {
  display: flex;
  position: relative;
  top: var(--icon-vertical-offset, initial);
  color: #ff6d07;
}
.elementor-widget .elementor-icon-list-icon i {
  width: 1.25em;
  font-size: var(--e-icon-list-icon-size);
}
.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
  text-align: var(--e-icon-list-icon-align);
}
.elementor-widget.elementor-align-center .elementor-icon-list-item {
  justify-content: center;
}
.elementor-widget.elementor-align-center .elementor-icon-list-item:after {
  margin: auto;
}
.elementor-widget.elementor-align-left .elementor-icon-list-item {
  justify-content: flex-start;
  text-align: left;
}
.elementor-widget.elementor-align-left .elementor-inline-items {
  justify-content: flex-start;
}
.elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
  left: 0;
}
.elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
  right: 0;
}
@media (min-width: -1) {
  .elementor-widget:not(.elementor-widescreen-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-widescreen-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
@media (max-width: -1) {
  .elementor-widget:not(.elementor-laptop-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-laptop-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
@media (max-width: -1) {
  .elementor-widget:not(.elementor-tablet_extra-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-tablet_extra-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
@media (max-width: 1024px) {
  .elementor-widget:not(.elementor-tablet-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-tablet-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
@media (max-width: -1) {
  .elementor-widget:not(.elementor-mobile_extra-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-mobile_extra-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
@media (max-width: 767px) {
  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item {
    justify-content: center;
  }
  .elementor-widget.elementor-mobile-align-center
    .elementor-icon-list-item:after {
    margin: auto;
  }
  .elementor-widget:not(.elementor-mobile-align-right)
    .elementor-icon-list-item:after {
    left: 0;
  }
  .elementor-widget:not(.elementor-mobile-align-left)
    .elementor-icon-list-item:after {
    right: 0;
  }
}
/*! CSS Used from: Embedded */
.elementor-widget-image-carousel .swiper {
  position: static;
}
.elementor-widget-image-carousel .swiper .swiper-slide figure {
  line-height: inherit;
}
.elementor-widget-image-carousel .swiper-slide {
  text-align: center;
}
/*! CSS Used from: Embedded */
.elementor-star-rating {
  color: #ccd6df;
  font-family: eicons;
  display: inline-block;
}
.elementor-star-rating i {
  display: inline-block;
  position: relative;
  font-style: normal;
  cursor: default;
}
.elementor-star-rating i:before {
  content: "\e934";
  display: block;
  font-size: inherit;
  font-family: inherit;
  position: absolute;
  overflow: hidden;
  color: #f0ad4e;
  top: 0;
  left: 0;
}
.elementor-star-rating .elementor-star-7:before {
  width: 70%;
}
.elementor-star-rating .elementor-star-8:before {
  width: 80%;
}
.elementor-star-rating__wrapper {
  display: flex;
  align-items: center;
}
.elementor-star-rating__title {
  margin-right: 10px;
}
.elementor-star-rating--align-center .elementor-star-rating__wrapper {
  text-align: center;
  justify-content: center;
}
.elementor--star-style-star_unicode .elementor-star-rating {
  font-family: Arial, Helvetica, sans-serif;
}
.elementor--star-style-star_unicode
  .elementor-star-rating
  i:not(.elementor-star-empty):before {
  content: "★";
}
/*! CSS Used from: Embedded ; media=all */
@media all {
  .st0 {
    fill: #4285f4;
  }
  .st1 {
    fill: #ea4335;
  }
  .st2 {
    fill: #fbbc05;
  }
  .st3 {
    fill: #34a853;
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor-pro/assets/css/widget-carousel.min.css */
.elementor-testimonial__image {
  flex-shrink: 0;
}
.elementor-testimonial__image img {
  border-radius: 999px;
  box-sizing: content-box;
  -o-object-fit: cover;
  object-fit: cover;
}
.elementor-testimonial__footer {
  display: flex;
}
.elementor-testimonial__footer cite {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-size: 14px;
  line-height: 1.5;
}
.elementor-testimonial__name {
  font-weight: 700;
}
.elementor-testimonial--layout-image_above .elementor-testimonial__footer {
  align-items: center;
}
.elementor-testimonial--layout-image_above
  .elementor-testimonial__image
  + cite {
  margin-left: 20px;
}
.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
  .elementor-testimonial__footer {
  flex-direction: row;
}
.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
  .elementor-testimonial__image
  + cite {
  margin-right: 0;
  margin-left: 20px;
}
.elementor-testimonial--layout-image_above .elementor-testimonial {
  display: flex;
  flex-direction: column-reverse;
}
.elementor-testimonial--layout-image_above .elementor-testimonial__footer {
  margin-bottom: 25px;
}
.elementor-testimonial--align-left .elementor-testimonial {
  text-align: left;
}
.elementor-testimonial--align-left.elementor-testimonial--layout-image_above
  .elementor-testimonial__footer {
  flex-direction: row;
  justify-content: start;
}
.elementor-testimonial--align-left.elementor-testimonial--layout-image_above
  .elementor-testimonial__image
  + cite {
  margin-right: 0;
  margin-left: 20px;
}
.elementor-testimonial--skin-bubble .elementor-testimonial__content {
  background-color: #f9fafa;
  padding: 20px;
  position: relative;
}
.elementor-testimonial--skin-bubble .elementor-testimonial__content:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-color: #f9fafa;
  position: absolute;
  transform: scaleX(0.75) rotate(45deg);
  border-width: 0;
  box-sizing: content-box;
}
.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above
  .elementor-testimonial__content:after {
  bottom: calc(100% - 7px);
  transform: scaleX(0.75) rotate(-135deg);
}
.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
  .elementor-testimonial__content:after {
  left: calc(20px + (50px / 2));
}
.elementor-widget-testimonial-carousel .swiper-slide {
  padding: 20px;
}
.elementor-widget-testimonial-carousel .elementor-testimonial__text {
  font-size: 1.3em;
  font-style: italic;
  line-height: 1.5;
}
.elementor-widget-testimonial-carousel .elementor-testimonial__image img {
  width: 50px;
  height: 50px;
}
.swiper-slide {
  will-change: transform;
  border-style: solid;
  border-width: 0;
  transition-duration: 0.5s;
  transition-property: border, background, transform;
  overflow: hidden;
}
.elementor-widget-testimonial-carousel .elementor-main-swiper {
  width: 95%;
}
.elementor-widget-testimonial-carousel .swiper-wrapper {
  display: flex;
  align-items: stretch;
}
.elementor-widget-testimonial-carousel .swiper-slide {
  height: auto;
}
/*! CSS Used from: Embedded */
.elementor-widget-divider {
  --divider-border-style: none;
  --divider-border-width: 1px;
  --divider-color: #0c0d0e;
  --divider-icon-size: 20px;
  --divider-element-spacing: 10px;
  --divider-pattern-height: 24px;
  --divider-pattern-size: 20px;
  --divider-pattern-url: none;
  --divider-pattern-repeat: repeat-x;
}
.elementor-widget-divider .elementor-divider {
  display: flex;
}
.elementor-widget-divider .elementor-divider-separator {
  display: flex;
  margin: 0;
  direction: ltr;
}
.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(
    .elementor-widget-divider--view-line_icon
  )
  .elementor-divider-separator {
  border-top: var(--divider-border-width) var(--divider-border-style)
    var(--divider-color);
}
.elementor-widget-divider--separator-type-pattern {
  --divider-border-style: none;
}
.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line
  .elementor-divider-separator {
  width: 100%;
  min-height: var(--divider-pattern-height);
  -webkit-mask-size: var(--divider-pattern-size) 100%;
  mask-size: var(--divider-pattern-size) 100%;
  -webkit-mask-repeat: var(--divider-pattern-repeat);
  mask-repeat: var(--divider-pattern-repeat);
  background-color: var(--divider-color);
  -webkit-mask-image: var(--divider-pattern-url);
  mask-image: var(--divider-pattern-url);
}
.e-con-inner > .elementor-widget-divider,
.e-con > .elementor-widget-divider {
  width: var(--container-widget-width, 100%);
  --flex-grow: var(--container-widget-flex-grow);
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/css/widget-icon-box.min.css */
.elementor-widget-icon-box .elementor-icon-box-wrapper {
  display: block;
  text-align: center;
}
.elementor-widget-icon-box .elementor-icon-box-icon {
  margin-bottom: var(--icon-box-icon-margin, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .elementor-widget-icon-box.elementor-vertical-align-middle
    .elementor-icon-box-wrapper {
    align-items: center;
  }
}
.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
  display: flex;
}
.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
  display: inline-flex;
  flex: 0 0 auto;
}
.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
  text-align: left;
  flex-direction: row;
}
.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
  margin-right: var(--icon-box-icon-margin, 15px);
  margin-left: 0;
  margin-bottom: unset;
}
@media (max-width: 767px) {
  .elementor-widget-icon-box.elementor-mobile-position-top
    .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
    flex-direction: unset;
  }
  .elementor-widget-icon-box.elementor-mobile-position-top
    .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto;
  }
  .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    display: block;
    flex: unset;
  }
}
.elementor-widget-icon-box .elementor-icon-box-content {
  flex-grow: 1;
}
.elementor-widget-icon-box .elementor-icon-box-description {
  margin: 0;
}
/*! CSS Used from: Embedded */
.elementor-widget-countdown .elementor-countdown-wrapper {
  flex-direction: row;
}
.elementor-widget-countdown .elementor-countdown-item {
  padding: 20px 0;
  text-align: center;
  color: #fff;
}
.elementor-widget-countdown .elementor-countdown-digits,
.elementor-widget-countdown .elementor-countdown-label {
  line-height: 1;
}
.elementor-widget-countdown .elementor-countdown-digits {
  font-size: 69px;
}
.elementor-widget-countdown .elementor-countdown-label {
  font-size: 19px;
}
.elementor-widget-countdown.elementor-countdown--label-block
  .elementor-countdown-wrapper {
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}
.elementor-widget-countdown.elementor-countdown--label-block
  .elementor-countdown-digits,
.elementor-widget-countdown.elementor-countdown--label-block
  .elementor-countdown-label {
  display: block;
}
.elementor-widget-countdown.elementor-countdown--label-block
  .elementor-countdown-item {
  flex-basis: 0;
  flex-grow: 1;
}
.elementor-widget-countdown.elementor-countdown--label-inline {
  text-align: center;
}
.elementor-widget-countdown.elementor-countdown--label-inline
  .elementor-countdown-item {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
/*! CSS Used from: Embedded */
.elementor-toggle {
  text-align: left;
}
.elementor-toggle .elementor-tab-title {
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #d5d8dc;
  cursor: pointer;
  outline: none;
}
.elementor-toggle .elementor-tab-title .elementor-toggle-icon {
  display: inline-block;
  width: 1em;
}
.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon.elementor-toggle-icon-left {
  float: left;
  text-align: left;
}
.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon
  .elementor-toggle-icon-closed {
  display: block;
}
.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon
  .elementor-toggle-icon-opened {
  display: none;
}
.elementor-toggle .elementor-tab-content {
  padding: 15px;
  border-bottom: 1px solid #d5d8dc;
  display: none;
}
@media (max-width: 767px) {
  .elementor-toggle .elementor-tab-title {
    padding: 12px;
  }
  .elementor-toggle .elementor-tab-content {
    padding: 12px 10px;
  }
}
.e-con-inner > .elementor-widget-toggle {
  width: var(--container-widget-width);
  --flex-grow: var(--container-widget-flex-grow);
}
/*! CSS Used from: Embedded */
.elementor-gallery__container {
  min-height: 1px;
}
.elementor-gallery-item {
  position: relative;
  overflow: hidden;
  display: block;
  text-decoration: none;
  border: solid var(--image-border-width) var(--image-border-color);
  border-radius: var(--image-border-radius);
}
.elementor-gallery-item__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.elementor-gallery-item__overlay {
  mix-blend-mode: var(--overlay-mix-blend-mode);
  transition-duration: var(--overlay-transition-duration);
  transition-property: mix-blend-mode, transform, opacity, background-color;
}
.elementor-gallery-item__image.e-gallery-image {
  transition-duration: var(--image-transition-duration);
  transition-property: filter, transform;
}
.e-gallery-item.elementor-gallery-item {
  transition-property: all;
}
.e-con-inner > .elementor-widget-gallery {
  width: var(--container-widget-width);
  --flex-grow: var(--container-widget-flex-grow);
}
/*! CSS Used from: https://be10x.in/wp-content/uploads/elementor/css/post-65.css?ver=1699337818 ; media=all */
@media all {
  .elementor-65 .elementor-element.elementor-element-82de257 {
    --display: flex;
    --background-transition: 0.3s;
  }
  .elementor-65 .elementor-element.elementor-element-fec5a57 .elementor-button {
    font-size: 24px;
    font-weight: 600;
    fill: var(--e-global-color-astglobalcolor5);
    color: var(--e-global-color-astglobalcolor5);
    background-color: var(--e-global-color-d384b9c);
    border-style: none;
    border-radius: 7px 7px 7px 7px;
    padding: 20px 20px 20px 20px;
  }
  .elementor-65 .elementor-element.elementor-element-ac82bda {
    text-align: center;
  }
  .elementor-65
    .elementor-element.elementor-element-ac82bda
    .elementor-widget-container {
    text-indent: 0px;
    mix-blend-mode: inherit;
  }
  @media (max-width: 767px) {
    .elementor-65 .elementor-element.elementor-element-82de257 {
      --padding-block-start: 0px;
      --padding-block-end: 0px;
      --padding-inline-start: 0px;
      --padding-inline-end: 0px;
    }
    .elementor-65
      .elementor-element.elementor-element-fec5a57
      .elementor-button {
      font-size: 18px;
      padding: 20px 20px 20px 20px;
    }
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/e-gallery/css/e-gallery.min.css?ver=1.2.0 ; media=all */
@media all {
  .e-gallery-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .e-gallery-container:not(.e-gallery-grid) {
    transition: padding-bottom var(--animation-duration);
  }
  .e-gallery-item {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    transition-property: all;
    transition-duration: var(--animation-duration);
  }
  .e-gallery-image {
    background-position: center center;
    background-size: cover;
    width: 100%;
    transform-origin: center top;
    transition: var(--animation-duration);
  }
  .e-gallery-masonry {
    height: 0;
    margin-bottom: calc(var(--highest-column-gap-count) * var(--vgap));
  }
  .e-gallery-masonry .e-gallery-item {
    position: absolute;
    width: calc(
      100% / var(--columns) -
        (var(--hgap) * (var(--columns) - 1) / var(--columns))
    );
    top: calc(var(--percent-height) + (var(--items-in-column) * var(--vgap)));
  }
  .e-gallery-masonry .e-gallery-image {
    padding-bottom: var(--item-height);
  }
  .e-gallery--ltr.e-gallery-masonry .e-gallery-item {
    left: calc(
      (
          100% / var(--columns) -
            (var(--hgap) * (var(--columns) - 1) / var(--columns))
        ) * var(--column) + (var(--hgap) * var(--column))
    );
  }
}
/*! CSS Used from: https://be10x.in/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=3.17.2 ; media=all */
@media all {
  .headShake {
    animation-timing-function: ease-in-out;
    animation-name: headShake;
  }
}
/*! CSS Used keyframes */
@keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2)
      format("woff2"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff)
      format("woff"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf)
      format("truetype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome)
      format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot);
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff2)
      format("woff2"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff)
      format("woff"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.ttf)
      format("truetype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.svg#fontawesome)
      format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot);
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff2)
      format("woff2"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff)
      format("woff"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.ttf)
      format("truetype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.svg#fontawesome)
      format("svg");
}
@font-face {
  font-family: eicons;
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.eot?5.23.0);
  src: url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.eot?5.23.0#iefix)
      format("embedded-opentype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.woff2?5.23.0)
      format("woff2"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.woff?5.23.0)
      format("woff"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.ttf?5.23.0)
      format("truetype"),
    url(https://be10x.in/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.svg?5.23.0#eicon)
      format("svg");
  font-weight: 400;
  font-style: normal;
}
